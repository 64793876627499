export default {
  sample: {
    status: [
      { id: "", name: "全部" },
      { id: 3, name: "A003" },
      { id: 2, name: "B002" },
      { id: 1, name: "有效" },
      { id: 0, name: "删除" },
    ],
  },
  quill: {
    option: {
      placeholder: "请输入内容",
      theme: "snow", // bubble
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          // [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          ["image"],
          ["clean"],
        ],
      },
    },
  },
  qa: {
    cate: {
      status: [
        { id: 1, name: "有效" },
        { id: 0, name: "删除" },
      ],
    },
    list: {
      status: [
        { id: 2, name: "发布" },
        { id: 1, name: "草稿" },
        { id: 0, name: "删除" },
      ],
    },
  },
  invoice: {
    type: [
      { id: 1, name: "电子普票" },
      { id: 2, name: "电子专票" },
    ],
    status: [
      { id: 1, name: "已确认" },
      { id: 0, name: "待审核" },
    ],
  },
  device: {
    ITEM_STATUS: {
      NORMAL: { val: 1, label: "正常" },
      APPLIED: { val: 2, label: "已领用" },
      BROKEN: { val: 3, label: "损坏" },
      LOST: { val: 4, label: "遗失" },
      DISPOSED: { val: 5, label: "报废" },
    },
    ITEM_ACT_TYPE: {
      APPLY: { val: 2, label: "申领" },
      RETURN: { val: 1, label: "归还" },
      BROKEN: { val: 3, label: "损坏" },
      LOST: { val: 4, label: "遗失" },
      DISPOSED: { val: 5, label: "报废" },
      DAILY_ISSUE: { val: 20, label: "日常问题" },
      DAILY_CHECK: { val: 21, label: "日常检查" },
    },
  },
  message: {
    //消息管理
    isReaded: [
      //是否已读
      { id: true, name: "已读" },
      { id: false, name: "未读" },
    ],
    isDel: [
      //是否删除
      { id: 0, name: "否" },
      { id: 1, name: "是" },
    ],
  },
};
